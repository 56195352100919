<template>
  <div v-if="hasResource('category_management')">
    <div>
      <div class="search-form">
        <el-button
          class="black-btn"
          @click="handleDialogAdds"
          v-if="hasResource('category_add')"
          >新增分类</el-button
        >
        <el-button
          class="white-btn"
          @click="
            isDelAssets = true;
            del('批量');
          "
          v-if="hasResource('category_delete')"
          >批量删除</el-button
        >
      </div>
      <div>
        <el-input
          placeholder="输入关键词检索"
          v-model="filterText"
          clearable
          class="search-input"
        ></el-input>
        <div class="text-button">
          <span @click="filterChange" class="text-button-item">查询</span>
          <span
            @click="
              filterText = '';
              init();
            "
            class="text-button-item"
            >清空</span
          >
        </div>
      </div>
      <div class="mg-t-24 assetsTree">
        <el-tree
          ref="assetTree"
          :data="menuData"
          show-checkbox
          :props="defaultProps"
          :filter-node-method="filterNode"
          :expand-on-click-node="expandNode"
          @node-click="handleNodeClick"
          @check-change="handleCheckChange"
          v-loading="isTreeLoading"
          @node-collapse="handleNOdeCollapse"
        >
          <div slot-scope="{ data }">
            <span style="display: inline-block">
              <span id="node.data.name"
                >{{ data.name_zh }}
                <span v-if="data.name_zh">/</span>
                {{ data.name }}({{ data.assets_count }})</span
              >
            </span>
            <span v-if="data.id == focusObj.id" style="margin-left: 160px">
              <el-button
                size="mini"
                type="text"
                @click="edit()"
                v-if="hasResource('category_edit')"
              >
                <i class="iconfont">&#xe677;</i>编辑
              </el-button>
              <el-button
                size="mini"
                type="text"
                @click="del(data)"
                v-if="hasResource('category_delete')"
              >
                <i class="iconfont">&#xe678;</i>删除
              </el-button>
            </span>
          </div>
        </el-tree>
      </div>
    </div>
    <div>
      <!-- 新增分类 -->
      <el-dialog
        top="12%"
        :close-on-click-modal="false"
        title="新增分类"
        :visible.sync="dialogAdds"
        width="444px"
        class="add-dialog addDialog pop-dialog"
        :modal-append-to-body="false"
      >
        <div style="margin-top: 20px">
          <div
            v-for="addItem in addMenuList"
            :key="addItem.id"
            class="menu-button mg-r-6"
          >
            <el-button
              :class="
                selectedMenu == addItem.name
                  ? 'button-checked'
                  : 'menu-button-item'
              "
              @click="handleSelectedMenu(addItem)"
              >{{ addItem.name }}</el-button
            >
          </div>
          <el-form
            ref="form"
            :model="form"
            :rules="rules"
            label-width="80px"
            @submit.native.prevent
          >
            <el-row>
              <el-col :span="24">
                <el-form-item label="中文名称" prop="name_zh">
                  <el-input
                    v-model="form.name_zh"
                    class="adddDalog-input"
                    placeholder="请输入"
                  />
                </el-form-item>
                <el-form-item label="英文名称" prop="type">
                  <el-input
                    v-model="form.type"
                    class="adddDalog-input"
                    placeholder="请输入"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="24" v-if="selectedMenu != '一级分类'">
                <el-form-item label="所属一级" prop="parent">
                  <el-select
                    v-model="form.parent"
                    placeholder="请选择"
                    class="adddDalog-input"
                    @change="handleFirst"
                  >
                    <el-option
                      v-for="main in options.mainMenu"
                      :key="main.id"
                      :label="main.name"
                      :value="main.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24" v-if="selectedMenu == '三级分类'">
                <el-form-item label="所属二级" prop="parent2">
                  <el-select
                    v-model="form.parent2"
                    placeholder="请选择"
                    class="adddDalog-input"
                    :disabled="
                      options.subMenu && options.subMenu.length > 0
                        ? false
                        : true
                    "
                  >
                    <el-option
                      v-for="item in options.subMenu"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button
            @click="
              dialogAdds = false;
              addSubmit('form');
            "
            class="confirm-button"
            :loading="isDialogAddLoading"
            >确 认</el-button
          >
          <el-button @click="dialogAdds = false" class="cancel-button"
            >取 消</el-button
          >
        </span>
      </el-dialog>

      <!-- 批量删除 -->
      <el-dialog
        top="12%"
        :close-on-click-modal="false"
        :visible.sync="dialogDels"
        width="458px"
        class="del-dialog"
        :modal-append-to-body="false"
      >
        <div class="del-title">
          <span v-if="selectedArr.length != 0">您将批量删除所选择的资产</span>
          <span v-else>
            您将删除分类
            <span>{{ focusObj.name }}</span>
          </span>
        </div>
        <div class="del-tips">
          提示：删除后，该分类及其子分类所绑定的资产，将自动解除绑定关系。
        </div>
        <span slot="footer" class="dialog-footer pop-dialog">
          <el-button
            @click="
              dialogDels = false;
              delSubmit();
            "
            class="confirm-button"
            :loading="isDialogDelLoading"
            >确 认</el-button
          >
          <el-button
            @click="
              dialogDels = false;
              this.focusObj = {};
              selectedArr = [];
            "
            class="cancel-button"
            >取 消</el-button
          >
        </span>
      </el-dialog>

      <el-dialog
        top="12%"
        :close-on-click-modal="false"
        :visible.sync="dialogDrag"
        width="458px"
        class="del-dialog"
        :modal-append-to-body="false"
      >
        <div class="del-title">
          <div class="drag-tip">
            您将移动资产分类
            <span class="drag-name">{{ node.name }}</span>
          </div>
          <div class="drag-tip">
            至分类
            <span class="drag-name">{{ node.dragName }}</span>
          </div>
        </div>
        <span slot="footer" class="dialog-footer pop-dialog">
          <el-button
            @click="
              dialogDels = false;
              dragSubmit();
            "
            class="confirm-button"
            :loading="isDialogDelLoading"
            >确 认</el-button
          >
          <el-button @click="dialogDrag = false" class="cancel-button"
            >取 消</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import api from "../../../api/asset";
export default {
  data() {
    return {
      header: {
        url: "assetsClassification",
      },
      options: {
        mainMenu: [],
        subMenu: [],
      },
      menuData: [],
      keyword: "",
      dialogAdds: false,
      dialogDels: false,
      isDialogAddLoading: false,
      isDialogDelLoading: false,
      isTreeLoading: false,
      isDelAssets: false,
      filterText: "",
      form: {
        type: "",
        name_zh: "",
        parent: "",
        parent2: "",
      },
      focusObj: {},
      focusName: null,
      focusId: null,
      selectedArr: [],
      selectedMenu: "一级分类",
      addMenuList: [
        { name: "一级分类", id: 1 },
        { name: "二级分类", id: 2 },
        { name: "三级分类", id: 3 },
      ],
      expandNode: true,
      addType: "",
      defaultProps: {
        children: "children",
        lable: "name",
      },
      rules: {
        type: [
          { required: true, message: "请输入英文名称", trigger: "blur" },
          {
            min: 1,
            max: 20,
            message: "长度在 1 到 20 个字符",
            trigger: "blur",
          },
        ],
        name_zh: [
          { required: true, message: "请输入中文名称", trigger: "blur" },
          {
            min: 1,
            max: 20,
            message: "长度在 1 到 20 个字符",
            trigger: "blur",
          },
        ],
        parent: [
          { required: true, message: "请选择一级分类", trigger: "blur" },
        ],
        parent2: [
          {
            required: true,
            message: "请创建所属二级分类",
            trigger: "blur",
          },
        ],
      },
      dialogDrag: false,
      node: {
        name: "",
        dragName: "",
      },
    };
  },
  watch: {
    // "form.parent": function (val, oldVal) {
    //   if (this.selectedMenu == "三级分类") {
    //     this.options.subMenu = this.options.mainMenu.filter((el) => {
    //       return el.id == val;
    //     })[0].children;
    //   }
    // },
  },
  created() {
    this.init();
  },
  activated() {
    if (this.$route.meta.keepAlive) {
      this.init();
    }
  },
  methods: {
    init() {
      this.isTreeLoading = true;
      let data = "US";
      api.getCategoryTree(data).then((resp) => {
        this.isTreeLoading = false;
        this.menuData = resp.data.data.children;
        this.options.mainMenu = this.menuData;
        // (this.menuData || []).forEach((ele) => {
        //   this.options.subMenu = this.options.subMenu.concat(ele.children);
        // });
      });
    },
    // OnTextChanged() {
    //   if (event.keyCode == 13) {//判断是否为回车键，Event是window对象的一个属性，是全局的。
    //     event.keyCode = 0;//屏蔽回车键
    //     event.returnValue = false;
    //   }
    // },
    handleFirst(e) {
      if (this.selectedMenu == "三级分类") {
        this.form.parent2 = "";
        this.options.subMenu = this.options.mainMenu.filter((el) => {
          return el.id == e;
        })[0].children;
      }
      // (this.menuData || []).forEach((ele) => {
      //   this.options.subMenu = this.options.subMenu.concat(ele.children);
      // });
    },
    filterNode(value, data) {
      if (!value) return true;
      return (
        data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
        data.name_zh.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
    },
    filterChange() {
      this.$refs.assetTree.filter(this.filterText);
    },
    handleSelectedMenu(item) {
      this.selectedMenu = item.name;
      this.form.type = "";
      this.form.name_zh = "";
      this.form.parent = "";
      this.form.parent2 = "";
      this.options.subMenu = [];
      this.$refs.form.clearValidate();
    },
    handleDialogAdds() {
      (this.selectedMenu = "一级分类"),
        (this.form = {
          type: "",
          name_zh: "",
          parent: "",
          parent2: "",
        });
      this.$nextTick(function() {
        this.$refs["form"].clearValidate();
      });
      this.dialogAdds = true;
    },
    addSubmit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          let parent =
            this.form.parent && this.selectedMenu == "二级分类"
              ? this.form.parent
              : this.form.parent2 && this.selectedMenu == "三级分类"
              ? this.form.parent2
              : null;
          let data = {
            market: "US",
            name: this.form.type,
            name_zh: this.form.name_zh,
            parent_id: parent,
            sort: 1,
          };
          api.addAssets(data).then((resp) => {
            if (resp.data.code == 10200) {
              this.init();
              this.dialogAdds = false;
              this.$$success("新增成功");
            } else {
              this.$$error(res.data.message);
            }
          });
        } else {
          this.dialogAdds = true;
          // return;
        }
      });
    },
    del(data) {
      if (
        this.selectedArr.length > 0 ||
        (data && data.id) ||
        (this.focusObj && this.focusObj.id && data != "批量")
      ) {
        this.dialogDels = true;
      } else if (data && data == "批量" && this.selectedArr.length == 0) {
        this.$$error("请先勾选数据~");
        return;
      }
    },
    delSubmit() {
      this.isDialogDelLoading = true;
      let idArr = [];
      if (!(JSON.stringify(this.focusObj) == "{}") && !this.isDelAssets) {
        // 单个删除
        idArr.push(this.focusObj.id);
      } else if (this.selectedArr.length != 0) {
        // 批量删除
        this.isDelAssets = false;
        let newArr = this.selectedArr;
        // 只点击二级批量删除
        if (
          this.selectedArr[0].children &&
          this.selectedArr[0].children.length == this.selectedArr.length
        ) {
          newArr = this.selectedArr.slice(1);
          newArr.forEach((el) => {
            if (el && el.level === 3) {
              idArr.push(el.id);
            }
          });
        }
        // 只点击三级批量删除
        else if (
          this.selectedArr[0].children &&
          this.selectedArr[0].children.length + 2 == this.selectedArr.length
        ) {
          newArr = this.selectedArr.slice(2);
          newArr.forEach((el) => {
            if (el && el.level === 4) {
              idArr.push(el.id);
            }
          });
        } else {
          newArr.forEach((el) => {
            if (el && el.id) {
              idArr.push(el.id);
            }
          });
        }
      }
      let data = {
        market: "US",
        id: idArr,
      };
      api.delAsset(data).then((resp) => {
        if (resp.data.code == 10200) {
          this.init();
          this.$$success("删除成功");
        } else {
          this.$$error(resp.data.message);
        }
        this.isDialogDelLoading = false;
      });
    },
    handleNodeClick(data, node) {
      this.focusObj = data;
    },
    handleCheckChange(data, value, node) {
      let res = this.$refs.assetTree
        .getCheckedNodes()
        .concat(this.$refs.assetTree.getHalfCheckedKeys());
      this.selectedArr = res;
    },
    handleNOdeCollapse(data, value, node) {
      this.focusObj = {};
    },
    edit() {
      this.$router.push({
        path: "/category",
        query: {
          id: this.focusObj.id,
          data: this.focusObj,
          path: this.focusObj.path,
          parent_id: this.focusObj.parent_id,
          level: this.focusObj.level,
        },
      });
    },
  },
};
</script>

<style lang="less">
@import "index.less";
</style>
